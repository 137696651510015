import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/noto-serif-jp/400.css';
import '@fontsource/noto-serif-jp/500.css';
import '@fontsource/noto-serif-jp/700.css';

export const shouldUpdateScroll = ({ routerProps }) => {
  const { location } = routerProps;
  const newPathname = location.pathname;

  const path = ['/blog/', '/tags/education/', '/tags/gto/', '/tags/service/', '/tags/tool/'];

  const NAVIGATION_HEIGHT = 60; // ナビゲーションバーの高さを設定

  // アンカーリンクが存在する場合
  if (location.hash) {
    setTimeout(() => {
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        const topPosition =
          element.getBoundingClientRect().top + window.pageYOffset - NAVIGATION_HEIGHT;
        window.scrollTo({ top: topPosition, behavior: 'smooth' });
      }
    }, 0);
    return false;
  }

  if (path.includes(newPathname)) {
    return true;
  }

  window.scrollTo(0, 0);
  return false;
};
